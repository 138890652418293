import consumer from "./consumer"

document.addEventListener('turbolinks:load', () => {
  var path = ["events_index", "managers_event_show", "shift_schedules_log"];
  var element = document.getElementById('room');
  var user_id = element != undefined ? element.getAttribute('data-user') : 0;

  setTimeout(function(){
    var room_id = element != undefined ? element.getAttribute('data-room') : 0;

    if(path.includes($('body').attr('data-controller')))
      setChannel(room_id);
  }, 3000);

  $("#fp-adEvTable").on('click', '[data-field="title"]', function(e, options){
    let room_id = $(this).closest('tr').data('event-id');
    $("#e_target_event_id").val(room_id);

    setChannel(room_id);
  });

  function setChannel(room_id){
    consumer.subscriptions.subscriptions.forEach((subscription) => {
      consumer.subscriptions.remove(subscription)
    });

    consumer.subscriptions.create({ channel: "RoomChannel", room_id: room_id }, {
      connected() {
        // Called when the subscription is ready for use on the server
        console.log("Connected to room channel >> " + room_id);
      },

      disconnected() {
        // Called when the subscription has been terminated by the server
      },

      received(data) {
        // Called when there's incoming data on the websocket for this channel
        console.log(data);
        if(user_id != data.shift.user_id){
          let shift = $('*[data-shift-code="'+data.shift.code+'"]');
          let sStatus = $(shift).parent().parent().parent().find('.fp-status');

          if(["check_in", "check_out"].includes(data.shift.type)){
            //checkin - out
            let type = data.shift.type == 'check_in' ? 0 : 1;
            let switcher = $(shift).parent().find('input');
            let sName = $(shift).parent().parent().parent().find('.fp-staff');
            var currentIn = parseInt($("#fp-teamTable-in").text());
            var currentOut = parseInt($("#fp-teamTable-out").text());

            if(switcher != undefined){
              $(switcher).prop('checked', type == 0 ? true : false);
              shift.data('log-status', type == 0 ? 1 : 2);

              if(type == 0){
                $("#fp-teamTable-in").text(currentIn + 1);
                $("#fp-teamTable-out").text(currentOut - 1);

                sName.removeClass('text-dark-75').addClass('fp-highlight');
                sStatus.text("Working");
              }
              else{
                $("#fp-teamTable-in").text(currentIn - 1);
                $("#fp-teamTable-out").text(currentOut + 1);

                sName.removeClass('fp-highlight').addClass('text-dark-75');
                sStatus.text("Checked Out");

                let statCol = $(shift).parent().find('.btn-switch-overlay').parent();
                $(statCol).html('<span class="btn btn-sm btn-warning btn-text font-weight-bolder p-2" style="width: 100%;">OUT</span>');
              }
            }
          }
          else if(["take_break", "back_work"].includes(data.shift.type)){
            var breakCount = parseInt($("#fp-team-break").text());

            //break
            if(data.shift.type == "take_break"){
              $("#fp-team-break").text(breakCount + 1);

              $(shift).parent().find('.fp-empty-break').parent().html('<span class="btn btn-outline-success border-0 btn-text p-0">\
                <img class="w-20px" src="/assets/break.png" />\
                <span class="text-success font-weight-bolder font-size-xs fp-staff-break" data-shift-code="'+ data.shift.code +'">x '+ data.shift.breaks +'</span></span>')
              sStatus.text("On Break");
            }
            else{
              $("#fp-team-break").text(breakCount - 1);
              $(shift).parent().find('.fp-staff-break').parent().html('<span class="btn btn-outline-success border-0 btn-text p-0">\
                <img class="w-20px" src="/assets/nonbreak.png"/>\
                <span class="text-muted font-weight-bolder mr-0 font-size-xs fp-empty-break" data-shift-code="'+ data.shift.code +'"> x'+ data.shift.breaks+'</span>')
              sStatus.text("Working");
            }
          }
        }
        else{
          if(["staff_csv_upload", "event_csv_upload"].includes(data.shift.type)) {
            $.ajax({
              url: '/v1/ajax/admins/info',
              beforeSend: function(xhr){ xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content')) },
              data: { title: data.shift.title, body: data.shift.body },
              type: 'POST',
              statusCode: {
                300: function(data) { },
                200: function(data) { }
              }
            });
          }
        }
      }
    });
  };
});
